import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';
import React, { useEffect, MouseEvent, useState, useMemo } from 'react';

import { parseLinks } from '../../../common/content';
import { HeaderQuery } from '../../../common/types/graphql';
import { useLocale } from '../../../context/locale';
import { Color } from '../../../theme/color';
import { Image } from '../../common/image';

import { Expanded } from './expanded';
import {
  HeaderWrapper,
  HeaderLinkList,
  HeaderLinkListItem,
  HeaderLink,
  LogoLink,
  NavigationToggleIcon,
  NavIcon,
  HeaderNavigation,
} from './header.styles';
import { HeaderProps } from './header.types';

export const Header = ({ theme }: HeaderProps) => {
  const locale = useLocale();

  const data = useStaticQuery<HeaderQuery>(graphql`
    query Header {
      header: allDatoCmsHeader {
        nodes {
          locale
          linksPrimary {
            ...LinkInternal
            ...LinkExternal
          }
          navigation {
            ...ItemHeader
          }
          logoWhite {
            gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
            url
          }
          logoBlack {
            gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
            url
          }
        }
      }
      pages: allDatoCmsPageModular(filter: { slug: { ne: null } }) {
        nodes {
          id
          headerTheme
          body {
            ...ModuleHeading
          }
        }
      }
    }
  `);

  const header = data.header.nodes.find((n) => n.locale === locale);
  const logoWhite = header?.logoWhite;
  const logoBlack = header?.logoBlack;
  const linksMap = header?.navigation
    .filter((item) => item.subnavigation)
    .reduce(
      (_linksMap, item) => ({
        [item.linkPrimary[0].page.id]: [],
        ..._linksMap,
      }),
      {},
    );

  data.pages.nodes.forEach((node) => {
    const { id } = node;

    if (linksMap[id]) {
      linksMap[id] = node.body
        .filter((block) => block.text)
        .map((block) => ({
          id: block.anchor,
          text: block.text,
        }));
    }
  });

  const linksPrimary = parseLinks(header?.linksPrimary);
  const [isOpened, setIsOpened] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const logo = useMemo(
    () => (theme === Color.BLACK ? logoBlack : logoWhite),
    [logoBlack, logoWhite, theme],
  );

  const onScroll = () => {
    const scrollTop = document?.scrollingElement?.scrollTop;

    setIsScrolling(true);

    if (scrollTop === 0) setIsScrolling(false);
  };

  const toggleisOpened = () => {
    setIsOpened(!isOpened);
  };

  const onClickBack = () => {
    setIsOpened(false);
  };

  const onToggleIcon = (e: MouseEvent) => {
    e.stopPropagation();
    toggleisOpened();
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    window.addEventListener('click', onClickBack);
    onScroll();

    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('click', onClickBack);
    };
  }, []);

  return (
    <HeaderWrapper onClick={onClickBack} isScrolling={isScrolling}>
      <HeaderNavigation>
        <LogoLink as={GatsbyLink} to={`/${locale}`} isScrolling={isScrolling}>
          <Image asset={logo} />
        </LogoLink>
        <HeaderLinkList>
          {!isOpened &&
            linksPrimary.map((link) => (
              <HeaderLinkListItem isScrolling={isScrolling} isOpened={isOpened}>
                {link.isInternal ? (
                  <HeaderLink as={GatsbyLink} to={link.url} theme={theme}>
                    {link.label}
                  </HeaderLink>
                ) : (
                  <HeaderLink href={link.url} theme={theme}>
                    {link.label}
                  </HeaderLink>
                )}
              </HeaderLinkListItem>
            ))}
          <NavigationToggleIcon onClick={onToggleIcon}>
            <NavIcon isOpened={isOpened} />
          </NavigationToggleIcon>
        </HeaderLinkList>
      </HeaderNavigation>
      <Expanded
        isOpened={isOpened}
        header={header}
        linksMap={linksMap}
        closeExpanded={toggleisOpened}
      />
    </HeaderWrapper>
  );
};
