import React, { PropsWithChildren } from 'react';
import rtlDetect from 'rtl-detect';

import { createCtx } from './create';

const DEFAULT_LOCALE = 'en';

type LocaleProps = {
  locale: string;
  links: {
    href: string;
    label: string;
  }[];
  defaultLocale: string;
  locales: [string];
  isRTL: boolean;
  direction: string;
  dictionaries: any[];
};

const [useCtx, CtxProvider] = createCtx<LocaleProps>();

type LocaleProviderProps = PropsWithChildren<
  Partial<Omit<LocaleProps, 'isRTL'>>
>;

const LocaleProvider = ({
  children,
  locale = DEFAULT_LOCALE,
  defaultLocale = DEFAULT_LOCALE,
  locales = [DEFAULT_LOCALE],
  links = [],
  dictionaries = [],
}: LocaleProviderProps) => {
  const isRTL = rtlDetect.isRtlLang(locale) ?? false;
  const direction = rtlDetect.getLangDir(locale);

  return (
    <CtxProvider
      value={{
        locale,
        links,
        defaultLocale,
        locales,
        isRTL,
        direction,
        dictionaries,
      }}
    >
      {children}
    </CtxProvider>
  );
};

const useLocale = () => {
  const context = useCtx();

  if (context === undefined) {
    throw new Error('useLocale must be used within a LocaleProvider');
  }

  return context.locale;
};

const useDictionaries = () => {
  const context = useCtx();

  if (context === undefined) {
    throw new Error('useDictionaries must be used within a LocaleProvider');
  }

  return context.dictionaries;
};

const useDefaultLocale = () => {
  const context = useCtx();

  if (context === undefined) {
    throw new Error('useDefaultLocale must be used within a LocaleProvider');
  }

  return context.defaultLocale;
};

const useLanguage = () => {
  const context = useCtx();

  if (context === undefined || context.locale === undefined) {
    throw new Error('useLanguage must be used within a LocaleProvider');
  }

  return context.locale ? context.locale.split('-')[0] : undefined;
};

const useLocales = () => {
  const context = useCtx();

  if (context === undefined) {
    throw new Error('useLocales must be used within a LocaleProvider');
  }

  return context.locales;
};

const useIsRTL = () => {
  const context = useCtx();

  if (context === undefined) {
    throw new Error('useIsRTL must be used within a LocaleProvider');
  }

  return context.isRTL;
};

const useDirection = () => {
  const context = useCtx();

  if (context === undefined) {
    throw new Error('useDirection must be used within a LocaleProvider');
  }

  return context.direction;
};

const useLinks = () => {
  const context = useCtx();

  if (context === undefined) {
    throw new Error('useDirection must be used within a LocaleProvider');
  }

  return context.links;
};

export {
  LocaleProvider,
  useLocale,
  useDefaultLocale,
  useLanguage,
  useIsRTL,
  useLocales,
  useDirection,
  useDictionaries,
  useLinks,
};
