import styled, { css } from 'styled-components';

import OpenNav from '../../../assets/icons/open_nav.inline.svg';
import { fadeInLong } from '../../../theme/animation';
import { Color, getColor } from '../../../theme/color';
import {
  ScreenSize,
  getMediaQuery,
  getUnitAsPixels,
} from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const HeaderWrapper = styled.header<{ isScrolling: boolean }>`
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  height: ${getUnitAsPixels({ multiplier: 5 })};
  transition-delay: ${({ isScrolling }) => (isScrolling ? `0.5s` : '0')};
  width: ${({ isScrolling }) =>
    isScrolling
      ? `calc(${getUnitAsPixels({ multiplier: 4 })} + ${getUnitAsPixels({
          multiplier: 3,
        })})`
      : '100%'};
  padding: ${({ isScrolling }) =>
    isScrolling
      ? `${getUnitAsPixels({ multiplier: 2 })} ${getUnitAsPixels({
          multiplier: 2,
        })} 0 0`
      : `${getUnitAsPixels({ multiplier: 2 })} ${getUnitAsPixels({
          multiplier: 2,
        })} 0`};
  left: ${({ isScrolling }) =>
    isScrolling
      ? `calc(100% - ${getUnitAsPixels({ multiplier: 4 })} - ${getUnitAsPixels({
          multiplier: 3,
        })})`
      : 0};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    height: ${getUnitAsPixels({ multiplier: 6 })};
    padding: ${({ isScrolling }) =>
      isScrolling
        ? `${getUnitAsPixels({ multiplier: 2 })} ${getUnitAsPixels({
            multiplier: 3,
          })} 0 0`
        : `${getUnitAsPixels({ multiplier: 2 })} ${getUnitAsPixels({
            multiplier: 3,
          })} 0`};
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    height: ${getUnitAsPixels({ multiplier: 8 })};
    width: ${({ isScrolling }) =>
      isScrolling
        ? `calc(${getUnitAsPixels({ multiplier: 5 })} + ${getUnitAsPixels({
            multiplier: 3,
          })})`
        : '100%'};
    padding: ${({ isScrolling }) =>
      isScrolling
        ? `${getUnitAsPixels({ multiplier: 3 })} ${getUnitAsPixels({
            multiplier: 3,
          })} 0 0`
        : `${getUnitAsPixels({ multiplier: 3 })} ${getUnitAsPixels({
            multiplier: 3,
          })} 0`};
    left: ${({ isScrolling }) =>
      isScrolling
        ? `calc(100% - ${getUnitAsPixels({
            multiplier: 5,
          })} - ${getUnitAsPixels({ multiplier: 3 })})`
        : 0};
  }
`;

export const LogoLink = styled.a<{ isScrolling: boolean }>`
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  opacity: ${({ isScrolling }) => (isScrolling ? 0 : 1)};
  z-index: ${({ isScrolling }) => (isScrolling ? -1 : 1)};
  width: ${({ isScrolling }) => (isScrolling ? '' : 'auto')};
  transition: all 0.4s ease-in-out;

  & > .gatsby-image-wrapper img,
  & > img {
    display: block;
    max-width: 100%;
    width: auto;
    height: ${getUnitAsPixels({ multiplier: 2.7 })};

    ${getMediaQuery(ScreenSize.MEDIUM)} {
      height: ${getUnitAsPixels({ multiplier: 3.25 })};
      margin-top: ${getUnitAsPixels({ multiplier: 2 })};
    }

    ${getMediaQuery(ScreenSize.LARGE)} {
      height: ${getUnitAsPixels({ multiplier: 4.25 })};
      margin-top: 0;
    }
  }
`;

export const NavigationToggleIcon = styled.div`
  position: fixed;
  top: ${getUnitAsPixels({ multiplier: 2 })};
  right: ${getUnitAsPixels({ multiplier: 2 })};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${getUnitAsPixels({ multiplier: 3 })};
  height: ${getUnitAsPixels({ multiplier: 3 })};
  cursor: pointer;
  z-index: 10;

  &:hover {
    transform: rotate(90deg);
    transition: transform 0.4s;
  }

  & > svg {
    width: 100%;
    height: 100%;
  }

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    width: ${getUnitAsPixels({ multiplier: 4 })};
    height: ${getUnitAsPixels({ multiplier: 4 })};
    top: ${getUnitAsPixels({ multiplier: 3 })};
    right: ${getUnitAsPixels({ multiplier: 3 })};
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    width: ${getUnitAsPixels({ multiplier: 5 })};
    height: ${getUnitAsPixels({ multiplier: 5 })};
  }
`;

export const NavIcon = styled(OpenNav)<{ isOpened: boolean }>`
  position: absolute;
  transform: ${({ isOpened }) => (isOpened ? 'rotate(-45deg)' : 'none')};
  transition: all 0.4s ease-in-out;

  & > rect {
    fill: ${({ isOpened }) =>
      isOpened ? `${getColor(Color.BLACK)}` : `${getColor(Color.RED)}`};
  }
`;

export const HeaderNavigation = styled.div`
  grid-column: span 12;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const HeaderLinkList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${getMediaQuery(ScreenSize.LARGE)} {
    padding-right: ${getUnitAsPixels({ multiplier: 5 })};
  }
`;

export const HeaderLinkListItem = styled.div<{
  isScrolling: boolean;
  isOpened: boolean;
}>`
  display: none;
  ${(props) =>
    props.isOpened
      ? css`
          animation: none;
        `
      : css`
          animation: ${fadeInLong} 500ms ease-in-out;
        `};

  ${getMediaQuery(ScreenSize.LARGE)} {
    display: block;
    overflow: hidden;
    margin-right: ${getUnitAsPixels({ multiplier: 3 })};
    opacity: ${({ isScrolling }) => (isScrolling ? 0 : 1)};
    z-index: ${({ isScrolling }) => (isScrolling ? -1 : 1)};
    width: ${({ isScrolling }) => (isScrolling ? '' : 'auto')};
    transition: all 0.4s ease-in-out;
  }
`;

export const HeaderLink = styled.a`
  ${getTypography(Type.NAVIGATION)}
  font-size: 16px;
  color: ${({ theme }) => getColor(theme)};

  &:hover {
    color: ${getColor(Color.RED)};
  }
`;

export const NavigationLinkList = styled.ul``;

export const NavigationLinkListItem = styled.div`
  margin-bottom: ${getUnitAsPixels()};
`;
