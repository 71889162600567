export enum Color {
  WHITE = 'WHITE',
  BLACK = 'BLACK',
  GREY_01 = 'GREY_01',
  GREY_02 = 'GREY_02',
  GREY_03 = 'GREY_03',
  GREY_04 = 'GREY_04',
  GREY_05 = 'GREY_05',
  RED = 'RED',
}

export enum Theme {
  WHITE = 'White',
  OFF_WHITE = 'Off-white',
  GREY = 'Grey',
  GREY_DARK = 'Grey-dark',
  BLACK = 'Black',
}

export type ThemeType = {
  background: {
    primary: string;
    secondary: string;
  };
  foreground: {
    primary: string;
    secondary: string;
  };
};

const colors: { [key in Color]: string } = {
  [Color.WHITE]: '#ffffff',
  [Color.BLACK]: '#000000',
  [Color.GREY_01]: '#F2F2F2',
  [Color.GREY_02]: '#DADADA',
  [Color.GREY_03]: '#A7A7A7',
  [Color.GREY_04]: '#505050',
  [Color.GREY_05]: '#292929',
  [Color.RED]: '#FF4343',
};

export const getColor = (color: Color): string => colors[color];

const themes: { [key in Theme]: ThemeType } = {
  [Theme.WHITE]: {
    background: {
      primary: getColor(Color.WHITE),
      secondary: getColor(Color.GREY_02),
    },
    foreground: {
      primary: getColor(Color.BLACK),
      secondary: getColor(Color.GREY_03),
    },
  },
  [Theme.OFF_WHITE]: {
    background: {
      primary: getColor(Color.GREY_01),
      secondary: getColor(Color.GREY_02),
    },
    foreground: {
      primary: getColor(Color.BLACK),
      secondary: getColor(Color.BLACK),
    },
  },
  [Theme.GREY]: {
    background: {
      primary: getColor(Color.GREY_01),
      secondary: getColor(Color.GREY_02),
    },
    foreground: {
      primary: getColor(Color.BLACK),
      secondary: getColor(Color.BLACK),
    },
  },
  [Theme.GREY_DARK]: {
    background: {
      primary: getColor(Color.GREY_03),
      secondary: getColor(Color.GREY_04),
    },
    foreground: {
      primary: getColor(Color.BLACK),
      secondary: getColor(Color.BLACK),
    },
  },
  [Theme.BLACK]: {
    background: {
      primary: getColor(Color.BLACK),
      secondary: getColor(Color.GREY_05),
    },
    foreground: {
      primary: getColor(Color.WHITE),
      secondary: getColor(Color.WHITE),
    },
  },
};

export const getTheme = (theme: Theme): ThemeType =>
  themes[theme] || themes[Theme.WHITE];
