export enum Font {
  FKGroteskBold = 'FKGroteskBold',
  FKGroteskRegular = 'FKGroteskRegular',
  FKGroteskLight = 'FKGroteskLight',
  FKGroteskSemiMono = 'FKGroteskSemiMono',
}

export const fonts: { [key in Font]: string } = {
  [Font.FKGroteskBold]: `"FKGroteskBold", sans-serif`,
  [Font.FKGroteskRegular]: `"FKGroteskRegular", sans-serif`,
  [Font.FKGroteskLight]: `"FKGroteskLight", sans-serif`,
  [Font.FKGroteskSemiMono]: `"FKGroteskSemiMono", sans-serif`,
};

export const getFont = (font: Font): string => fonts[font];
