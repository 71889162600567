import { Link as GatsbyLink } from 'gatsby';
import React, { useState, useCallback, useEffect, MouseEvent } from 'react';

import ArrowNavBack from '../../../../assets/icons/arrow_nav_back.inline.svg';
import ItemClose from '../../../../assets/icons/item_close.inline.svg';
import SubNav from '../../../../assets/icons/subnav.inline.svg';
import { parseLinks } from '../../../../common/content';
import { InternalTextLink } from '../../../../common/types';
import { Locales } from '../../../common/locales';

import {
  ExpandedWrapper,
  ExpandedContainer,
  ExpandedLinkList,
  ExpandedLinkListItem,
  ExpandedLink,
  ExpandedLinkListItemWrapper,
  ExpandedLinkListItemToggler,
  SubExpandedContainer,
  SubExpandedBack,
  SubExpandedLinkList,
  SubExpandedLinkListItem,
  SubExpandedLink,
  LocalesWrapper,
} from './expanded.styles';
import { ExpandedProps, VisualState } from './expanded.types';

const useToggleExpanded = (
  initialSubExpanded: InternalTextLink | null = null,
): [InternalTextLink | null, any] => {
  const [subExpanded, setSubExpanded] = useState(initialSubExpanded);
  const toggle = useCallback((newSubnavigation: InternalTextLink | null) => {
    setSubExpanded((_subExpanded) => {
      if (_subExpanded === newSubnavigation) {
        return null;
      }

      return newSubnavigation;
    });
  }, []);

  return [subExpanded, toggle];
};

const stopPropogation = (e: MouseEvent) => e.stopPropagation();

export const Expanded = ({
  isOpened,
  header,
  closeExpanded,
  linksMap,
}: ExpandedProps) => {
  const [visualState, setVisualState] = useState<VisualState>('closed');
  const [openedSubExpanded, updateOpenedSubExpanded] = useToggleExpanded();
  const toggleSubExpanded = (link: InternalTextLink | null) => {
    if (openedSubExpanded?.label === link?.label) {
      setVisualState('closing-subsection');
    } else {
      updateOpenedSubExpanded(link);
      setVisualState('opened-subsection');
    }
  };

  useEffect(() => {
    if (isOpened && visualState === 'closed') {
      setVisualState('opened');
    } else if (!isOpened && visualState === 'opened') {
      setVisualState('closing');
    } else if (!isOpened && visualState === 'opened-subsection') {
      setVisualState('closing-subsection');
    }
  }, [isOpened, visualState, setVisualState]);

  if (visualState === 'closed') {
    return null;
  }

  return (
    <ExpandedWrapper onClick={stopPropogation}>
      <ExpandedContainer
        state={visualState}
        onAnimationEnd={() => {
          if (visualState === 'closing') {
            setVisualState('closed');
            updateOpenedSubExpanded();
          }
        }}
      >
        <ExpandedLinkList>
          {header.navigation.map((item) => (
            <ExpandedLinkListItem key={item.id}>
              {parseLinks(item.linkPrimary).map((link) => {
                let subItems = [];

                if (link?.pageId) {
                  subItems = linksMap[link.pageId];
                }

                if (subItems?.length) {
                  return (
                    <ExpandedLinkListItemWrapper
                      active={openedSubExpanded?.label === link.label}
                      onClick={() => toggleSubExpanded(link)}
                    >
                      <ExpandedLink>{link.label}</ExpandedLink>
                      <ExpandedLinkListItemToggler>
                        {openedSubExpanded ? <ItemClose /> : <SubNav />}
                      </ExpandedLinkListItemToggler>
                    </ExpandedLinkListItemWrapper>
                  );
                }

                if (link.isInternal) {
                  return (
                    <ExpandedLink
                      as={GatsbyLink}
                      to={link.url}
                      onClick={closeExpanded}
                    >
                      {link.label}
                    </ExpandedLink>
                  );
                }

                return (
                  <ExpandedLink href={link.url} onClick={closeExpanded}>
                    {link.label}
                  </ExpandedLink>
                );
              })}
            </ExpandedLinkListItem>
          ))}
        </ExpandedLinkList>
        {process.env.GATSBY_LOCALIZATION_ENABLE && (
          <LocalesWrapper>
            <Locales />
          </LocalesWrapper>
        )}
      </ExpandedContainer>
      {(visualState === 'closing-subsection' ||
        visualState === 'opened-subsection') &&
        openedSubExpanded && (
          <SubExpandedContainer
            state={visualState}
            onAnimationEnd={() => {
              if (visualState === 'closing-subsection') {
                if (isOpened) {
                  setVisualState('opened');
                } else {
                  setVisualState('closing');
                }
                updateOpenedSubExpanded();
              }
            }}
          >
            <SubExpandedBack
              onClick={() => setVisualState('closing-subsection')}
            >
              <ArrowNavBack />
            </SubExpandedBack>
            <SubExpandedLinkList>
              {linksMap[openedSubExpanded.pageId].map((item) => {
                const url = `${openedSubExpanded.url}#${item.id}`;

                return (
                  <SubExpandedLinkListItem key={item.id}>
                    <SubExpandedLink
                      to={url}
                      onAnchorLinkClick={() => closeExpanded()}
                    >
                      {item.text}
                    </SubExpandedLink>
                  </SubExpandedLinkListItem>
                );
              })}
            </SubExpandedLinkList>
          </SubExpandedContainer>
        )}
    </ExpandedWrapper>
  );
};
