import React, { useEffect, useState } from 'react';
import { Link as GatsbyLink } from 'gatsby';

import { Container } from '../container';
import { Wrapper, Text, Accept, Link } from './cookies-modal.styles';
import ArrowToRight from '../../../assets/icons/arrow_to_right.inline.svg';
import { useLocale } from '../../../context/locale';

export const CookiesModal = () => {
  const [isShow, setShowState] = useState(false);
  const local = useLocale();
  const setCookieHandler = () => {
    document.cookie = `cookies=${new Date().toJSON()}; max-age=2419200`;
    setShowState(false);
  };
  useEffect(() => {
    if (!document.cookie) {
      setShowState(true);
    }
  }, []);
  return isShow ? (
    <Wrapper>
      <Container>
        <Text>
          By continuing to use this site you consent with our cookie policy. You
          can read more{' '}
          <Link as={GatsbyLink} to={`/${local}/legal/cookie-policy`}>
            here
          </Link>
          .
        </Text>
        <Accept onClick={setCookieHandler}>
          <Text>Accept</Text>
          <ArrowToRight />
        </Accept>
      </Container>
    </Wrapper>
  ) : (
    <></>
  );
};
