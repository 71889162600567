import styled from 'styled-components';

import { Color, getColor } from '../../../theme/color';
import {
  getMediaQuery,
  ScreenSize,
  GRID_LAYOUT,
  getUnitAsPixels,
} from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const FooterWrapper = styled.footer`
  display: grid;

  ${getMediaQuery(ScreenSize.LARGE)} {
    min-height: 100vh;
  }
`;

export const NavigationRow = styled.footer`
  background-color: ${getColor(Color.GREY_03)};
`;

export const LinkList = styled.ul`
  grid-column: span 12;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: span 6;
    margin-bottom: ${getUnitAsPixels({ multiplier: 3 })};
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: span 6;
    margin-bottom: ${getUnitAsPixels({ multiplier: 7 })};
  }
`;

export const LinkListItem = styled.li``;

export const Link = styled.a`
  display: inline-block;
  ${getTypography(Type.HEADING_3)};
  color: ${getColor(Color.BLACK)};
  transition: opacity 0.1s;
  line-height: 1.3;

  &:hover {
    opacity: 0.5;
  }
`;

export const Socials = styled.div`
  grid-column: span 12;
  margin-top: ${getUnitAsPixels({ multiplier: 3 })};
  margin-bottom: ${getUnitAsPixels({ multiplier: 3 })};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: span 6;
    margin-top: 0;
    margin-bottom: 0;
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: span 3;
  }
`;

export const LocalesWrapper = styled.div`
  grid-column: span 12;

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: span 3;
  }

  & a {
    ${getTypography(Type.HEADING_3)};
  }
`;

export const InfoRow = styled.div`
  ${GRID_LAYOUT}
  align-self: end;
  align-items: end;
  grid-column: span 12;
  margin-top: ${getUnitAsPixels({ multiplier: 3 })};
  margin-bottom: -${getUnitAsPixels({ multiplier: 2 })};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    margin-top: ${getUnitAsPixels({ multiplier: 3 })};
    margin-bottom: -${getUnitAsPixels({ multiplier: 3 })};
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    margin-top: 0;
    margin-bottom: -${getUnitAsPixels({ multiplier: 3 })};
  }
`;

export const Logo = styled.div`
  grid-column: span 12;
  font-size: 0;
  order: 2;

  & > img {
    max-width: 100%;
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: span 6;
    order: 1;
    margin-bottom: 0;
  }
`;

export const LegalInfoWrapper = styled.div`
  grid-column: span 12;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-bottom: ${getUnitAsPixels({ multiplier: 5 })};
  order: 1;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    margin-bottom: ${getUnitAsPixels({ multiplier: 7 })};
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: span 6;
    order: 2;
    margin-bottom: 5%;
  }
`;

export const LegalInfoLinkList = styled.ul`
  display: flex;
  margin-bottom: ${getUnitAsPixels({ multiplier: 1.5 })};
  flex-direction: column;
  margin-bottom: ${getUnitAsPixels({ multiplier: 2 })};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    margin-bottom: ${getUnitAsPixels({ multiplier: 3 })};
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    margin-bottom: ${getUnitAsPixels()};
    flex-direction: row;
  }
`;

export const LegalInfoLinkListItem = styled.li`
  ${getMediaQuery(ScreenSize.MEDIUM)} {
    margin-bottom: ${getUnitAsPixels()};

    &:not(:last-child) {
      margin-right: ${getUnitAsPixels({ multiplier: 2 })};
    }
  }
`;

export const LegalLink = styled.a`
  ${getTypography(Type.BODY_SMALL)};
  color: ${getColor(Color.BLACK)};
  text-decoration: none;
  transition: opacity 0.1s;
  line-height: 1.45;

  &:hover {
    opacity: 0.5;
  }
`;

export const LegalInfo = styled.p`
  ${getTypography(Type.BODY_SMALL)};
  color: ${getColor(Color.BLACK)};
  text-decoration: none;
`;
