export const pixelate = (value: number | undefined): string => `${value}px`;

export const round = (num: number): number =>
  Math.floor(num * 1000000) / 1000000;

export const getWindowWidth = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth;
  }

  return undefined;
};
