import styled, { css } from 'styled-components';

import { Color, getColor } from '../../../theme/color';
import { getUnitAsPixels } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const hoverState = css`
  :hover {
    color: ${getColor(Color.RED)};
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  bottom: ${getUnitAsPixels({ multiplier: 3 })};
  z-index: 100;
  width: 100%;
  & > div {
    background: none;
    & > div {
      background: #000;
      border-radius: 3px;
      color: #fff;
      padding: ${getUnitAsPixels({ multiplier: 3 })};
      display: flex;
      justify-content: space-between;
    }
  }
`;

export const Text = styled.div`
  ${getTypography(Type.BODY_SMALL)}
`;

export const Accept = styled.div`
  ${hoverState}
  display: flex;
  align-items: center;
  cursor: pointer;
  & > svg {
    ${getTypography(Type.BODY_SMALL_ICON)}
    margin-left: ${getUnitAsPixels({ multiplier: 0.5 })};
    path {
      fill: ${getColor(Color.RED)};
    }
  }
`;

export const Link = styled.a`
  cursor: pointer;
  text-decoration: underline;
  color: ${getColor(Color.WHITE)};
  ${hoverState}
`;
