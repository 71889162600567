import styled from 'styled-components';

import { Color, getColor } from '../../../theme/color';
import {
  ScreenSize,
  getMediaQuery,
  getUnitAsPixels,
} from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const LinkList = styled.div<{ directionColumn: boolean }>`
  display: flex;

  ${getMediaQuery(ScreenSize.LARGE)} {
    flex-direction: ${({ directionColumn }) =>
      directionColumn ? `column` : `row`};
  }
`;

export const Link = styled.a<{ directionColumn: boolean }>`
  ${getTypography(Type.BODY_REGULAR)}
  color: rgba(0,0,0, 0.3);
  text-decoration: none;
  margin-right: ${getUnitAsPixels()};

  ${getMediaQuery(ScreenSize.LARGE)} {
    margin-right: ${({ directionColumn }) =>
      directionColumn ? 0 : `${getUnitAsPixels()}`};
  }

  &:hover {
    color: ${getColor(Color.WHITE)};
  }

  &[aria-current='page'] {
    color: ${getColor(Color.BLACK)};
    cursor: default;
  }
`;
