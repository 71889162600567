import { get } from 'lodash';

export const isInternal = (url: string) => {
  // check against regex
  return /^\/(?!\/)/.test(url);
};

export const tidyURL = (url: string) => {
  // remove this domain
  const domain = process.env.URL ?? '';

  return url.replace(domain, '');
};

export const getLocalizedPath = ({ locale }: { locale?: string }) => {
  return `${locale ? `/${locale}` : ``}`;
};

export const standardiseInternalURL = (url: string) => {
  return url.replace(/\/?(\?|#|$)/, '/$1').toLowerCase();
};

export const getPageHomePath = ({ locale }: { locale?: string }) => {
  return `${getLocalizedPath({ locale })}/`;
};

export const getPageModularPath = ({
  locale,
  slug,
}: {
  locale?: string;
  slug?: string;
}) => {
  return `${getLocalizedPath({ locale })}/${slug ?? ''}`;
};

export const getPageUpdatePath = ({
  locale,
  slug,
}: {
  locale?: string;
  slug?: string;
}) => {
  return `${getLocalizedPath({ locale })}/${slug ?? ''}`;
};

export const getPageLegalPath = ({
  locale,
  slug,
}: {
  locale?: string;
  slug?: string;
}) => {
  return `${getLocalizedPath({ locale })}/legal/${slug ?? ''}`;
};

export const getCareersJobPath = ({
  locale,
  slug,
}: {
  locale?: string;
  slug?: string;
}) => {
  return `${getLocalizedPath({ locale })}/careers/${slug ?? ''}`;
};

export const getNewsArticlePath = ({
  locale,
  slug,
}: {
  locale?: string;
  slug?: string;
}) => {
  return `${getLocalizedPath({ locale })}/news/${slug ?? ''}`;
};

export const getNewsHomePath = ({ locale }: { locale?: string }) => {
  return `${getLocalizedPath({ locale })}/news`;
};

export const getInternalURL = ({
  pageType,
  locale,
  slug,
}: {
  pageType: string;
  locale?: string;
  slug?: string;
}): string => {
  switch (pageType) {
    case 'page_home':
      return getPageHomePath({ locale });
    case 'careers_job':
      return getCareersJobPath({ locale, slug });
    case 'page_modular':
      return getPageModularPath({ locale, slug });
    case 'page_update':
      return getPageUpdatePath({ locale, slug });
    case 'page_legal':
      return getPageLegalPath({ locale, slug });
    case 'news_home':
      return getNewsHomePath({ locale });
    case 'news_article':
      return getNewsArticlePath({ locale, slug });
    default:
      return '/';
  }
};

export const getInternalURLFromPage = ({
  model,
  locale,
  slug,
}: {
  model: { apiKey: string };
  locale?: string;
  slug?: string;
}): string => {
  return getInternalURL({ pageType: get(model, 'apiKey'), locale, slug });
};

export const getAbsoluteURL = ({
  url,
  pageType,
  locale,
  slug,
}: {
  url: string;
  pageType: string;
  locale?: string;
  slug?: string;
}) => {
  return `${url}${getInternalURL({ pageType, locale, slug })}`;
};

export const removeTrailingSlash = (path: string) => {
  return path.length > 1 ? path.replace(/\/$/, '') : path;
};

export const getEmailURL = (address: string) => {
  return `mailto:${address}`;
};

export const getPhoneURL = (phone: string) => {
  return `tel:${phone.replace(/\s+/g, '')}`;
};
