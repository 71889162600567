exports.components = {
  "component---src-templates-error-404-tsx": () => import("./../../../src/templates/error404.tsx" /* webpackChunkName: "component---src-templates-error-404-tsx" */),
  "component---src-templates-news-article-tsx": () => import("./../../../src/templates/newsArticle.tsx" /* webpackChunkName: "component---src-templates-news-article-tsx" */),
  "component---src-templates-news-home-tsx": () => import("./../../../src/templates/newsHome.tsx" /* webpackChunkName: "component---src-templates-news-home-tsx" */),
  "component---src-templates-page-home-tsx": () => import("./../../../src/templates/pageHome.tsx" /* webpackChunkName: "component---src-templates-page-home-tsx" */),
  "component---src-templates-page-legal-tsx": () => import("./../../../src/templates/pageLegal.tsx" /* webpackChunkName: "component---src-templates-page-legal-tsx" */),
  "component---src-templates-page-modular-tsx": () => import("./../../../src/templates/pageModular.tsx" /* webpackChunkName: "component---src-templates-page-modular-tsx" */),
  "component---src-templates-page-update-tsx": () => import("./../../../src/templates/pageUpdate.tsx" /* webpackChunkName: "component---src-templates-page-update-tsx" */)
}

