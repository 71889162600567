import styled from 'styled-components';

import {
  BLOCK_LAYOUT,
  CONTAINER,
  getVerticalSpacingAsStyle,
  GRID_LAYOUT,
  VerticalSpacingType,
  VerticalSpacingVariant,
} from '../../../theme/layout';

import { ContainerDisplay } from './container.types';

export const Outer = styled.div`
  ${CONTAINER}
  background-color: ${({ theme }) => theme.background.primary};
`;

export const Inner = styled.div<{
  borderTop: boolean;
  borderBottom: boolean;
  spaceTop: VerticalSpacingVariant;
  spaceBottom: VerticalSpacingVariant;
  display: ContainerDisplay;
  innerPageTitle: boolean;
}>`
  ${({ display }) =>
    display === ContainerDisplay.GRID ? GRID_LAYOUT : BLOCK_LAYOUT}
  border-top: ${({ theme, borderTop, innerPageTitle }) =>
    innerPageTitle
      ? 'none'
      : borderTop
      ? `1px solid ${theme.background.secondary}`
      : `none`};
  border-bottom: ${({ theme, borderBottom }) =>
    borderBottom ? `1px solid ${theme.background.secondary}` : `none`};

  ${({ spaceTop, spaceBottom, innerPageTitle }) =>
    innerPageTitle
      ? `${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING, {
          top: VerticalSpacingVariant.LARGE,
          bottom: VerticalSpacingVariant.LARGE,
        })}`
      : getVerticalSpacingAsStyle(VerticalSpacingType.PADDING, {
          top: spaceTop,
          bottom: spaceBottom,
        })}
`;
