import React from 'react';

import {
  Container,
  Content,
  Heading,
  Inner,
  Text,
} from './errorMessage.styles';

export const ErrorMessage = ({
  heading,
  text,
}: {
  heading: string;
  text: string;
}) => {
  return (
    <Container>
      <Content>
        <Inner>
          <Heading>{heading}</Heading>
          <Text>{text}</Text>
        </Inner>
      </Content>
    </Container>
  );
};
