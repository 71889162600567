import React from 'react';

import { VerticalSpacingVariant } from '../../../theme/layout';

import { Inner, Outer } from './container.styles';
import { ContainerDisplay, ContainerProps } from './container.types';

export const Container = ({
  children,
  spaceTop = VerticalSpacingVariant.SMALL,
  spaceBottom = VerticalSpacingVariant.SMALL,
  borderTop = false,
  borderBotton = false,
  display = ContainerDisplay.GRID,
  innerPageTitle = false,
}: ContainerProps) => {
  return (
    <Outer>
      <Inner
        borderTop={borderTop}
        borderBottom={borderBotton}
        spaceTop={spaceTop}
        spaceBottom={spaceBottom}
        display={display}
        innerPageTitle={innerPageTitle}
      >
        {children}
      </Inner>
    </Outer>
  );
};
