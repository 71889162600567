import { AnchorLink } from 'gatsby-plugin-anchor-links';
import styled from 'styled-components';

import { fadeInLeftNav, fadeInRightNav } from '../../../../theme/animation';
import { Color, getColor } from '../../../../theme/color';
import {
  ScreenSize,
  getMediaQuery,
  getUnitAsPixels,
  borderRadius,
} from '../../../../theme/layout';
import { getTypography, Type } from '../../../../theme/typography';

import { VisualState } from './expanded.types';

export const ExpandedWrapper = styled.div`
  display: flex;
  position: absolute;
  right: ${getUnitAsPixels()};
  top: ${getUnitAsPixels()};
  width: calc(100vw - ${getUnitAsPixels({ multiplier: 2 })});
  height: calc(100vh - ${getUnitAsPixels({ multiplier: 2 })});
  overflow-x: hidden;
  overflow-y: auto;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    width: auto;
    right: ${getUnitAsPixels({ multiplier: 1.5 })};
    top: ${getUnitAsPixels({ multiplier: 1.5 })};
    height: calc(100vh - ${getUnitAsPixels({ multiplier: 3 })});
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    right: ${getUnitAsPixels({ multiplier: 2 })};
    top: ${getUnitAsPixels({ multiplier: 2 })};
    height: calc(100vh - ${getUnitAsPixels({ multiplier: 4 })});
  }
`;

export const ExpandedContainer = styled.div<{ state: VisualState }>`
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100vw - ${getUnitAsPixels({ multiplier: 2 })});
  height: 100%;
  padding: ${getUnitAsPixels({ multiplier: 14 })}
    ${getUnitAsPixels({ multiplier: 2 })} ${getUnitAsPixels({ multiplier: 2 })};
  background-color: ${getColor(Color.RED)};
  backdrop-filter: blur(40px);
  border-radius: ${borderRadius};
  z-index: 1;
  overflow-y: auto;
  animation: ${(props) =>
      ({
        closed: fadeInRightNav,
        closing: fadeInRightNav,
        opened: fadeInLeftNav,
        'closing-subsection': fadeInLeftNav,
        'opened-subsection': fadeInLeftNav,
      }[props.state])}
    0.4s ease-in-out;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    position: relative;
    left: ${(props) =>
      props.state === 'opened-subsection' ||
      props.state === 'closing-subsection'
        ? '300px'
        : null};
    transform: ${(props) =>
      props.state === 'opened-subsection'
        ? 'translateX(-300px)'
        : 'translateX(0)'};
    transition: transform 400ms ease-in-out;
    width: 300px;
    background-color: rgba(255, 67, 67, 0.9);
    backdrop-filter: blur(20px);
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    left: ${(props) =>
      props.state === 'opened-subsection' ||
      props.state === 'closing-subsection'
        ? '400px'
        : null};
    transform: ${(props) =>
      props.state === 'opened-subsection'
        ? 'translateX(-400px)'
        : 'translateX(0)'};
    width: 400px;
    padding-top: ${getUnitAsPixels({ multiplier: 20 })};
  }
`;

export const LocalesWrapper = styled.div`
  margin-top: ${getUnitAsPixels({ multiplier: 8 })};
`;

export const ExpandedLinkList = styled.ul``;

export const ExpandedLinkListItem = styled.div`
  margin-bottom: ${getUnitAsPixels()};
`;

export const ExpandedLink = styled.div`
  display: block;
  flex-grow: 1;
  ${getTypography(Type.BODY_REGULAR)}
  color: ${getColor(Color.BLACK)};
  text-decoration: none;

  &:hover {
    color: ${getColor(Color.WHITE)};
  }

  &[aria-current='page'] {
    color: ${getColor(Color.WHITE)};
  }
`;

export const ExpandedLinkListItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  ${({ active }: { active: boolean }) =>
    active &&
    `
    & * {
      color: ${getColor(Color.WHITE)};
      path {
        stroke: ${getColor(Color.WHITE)};
      }
    }

  `}
  &:hover {
    & * {
      color: ${getColor(Color.WHITE)};
      path {
        stroke: ${getColor(Color.WHITE)};
      }
    }
  }
`;

export const ExpandedLinkListItemToggler = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: ${getUnitAsPixels({ multiplier: 5 })};
  height: ${getUnitAsPixels({ multiplier: 3 })};
  ${getTypography(Type.BODY_REGULAR)}
  cursor: pointer;
`;

export const SubExpandedContainer = styled.div<{ state: VisualState }>`
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100vw - ${getUnitAsPixels({ multiplier: 2 })});
  height: 100%;
  padding: ${getUnitAsPixels({ multiplier: 14 })}
    ${getUnitAsPixels({ multiplier: 2 })} ${getUnitAsPixels({ multiplier: 2 })};
  background-color: ${getColor(Color.GREY_01)};
  border-radius: ${borderRadius};
  overflow-y: auto;
  z-index: 2;
  animation: ${(props) =>
      ({
        opened: fadeInRightNav,
        closing: fadeInRightNav,
        closed: fadeInRightNav,
        'closing-subsection': fadeInRightNav,
        'opened-subsection': fadeInLeftNav,
      }[props.state])}
    0.4s ease-in-out;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    position: relative;
    width: 300px;
    margin-right: -${borderRadius};
    left: -${borderRadius};
    border-radius: 0px ${borderRadius} ${borderRadius} 0px;
    background-color: rgba(242, 242, 242, 0.9);
    backdrop-filter: blur(20px);
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    width: 400px;
    padding-top: ${getUnitAsPixels({ multiplier: 20 })};
  }
`;

export const SubExpandedBack = styled.div`
  position: absolute;
  top: ${getUnitAsPixels()};
  left: ${getUnitAsPixels({ multiplier: 2 })};
  cursor: pointer;
  font-size: 18px;
  color: ${getColor(Color.BLACK)};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    display: none;
  }
`;

export const SubExpandedLinkList = styled.ul``;

export const SubExpandedLinkListItem = styled.div`
  ${getTypography(Type.BODY_REGULAR)}
  color: ${getColor(Color.BLACK)};
  cursor: pointer;
  margin-bottom: 8px;

  &:hover {
    color: ${getColor(Color.WHITE)};
  }
`;

export const SubExpandedLink = styled(AnchorLink)`
  display: block;
  ${getTypography(Type.BODY_REGULAR)}
  color: ${getColor(Color.BLACK)};
  text-decoration: none;

  &:hover {
    color: ${getColor(Color.RED)};
  }

  &[aria-current='page'] {
    color: ${getColor(Color.RED)};
  }
`;
