import { keyframes } from 'styled-components';

export const fadeIn = () => keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const fadeInLong = () => keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const fadeInLeftNav = () => keyframes`
  0% {
    opacity: 0;
    transform: translateX(400px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const fadeInRightNav = () => keyframes`
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(400px);
  }
`;

export const fadeInLeftSlide = () => keyframes`
  0% {
    opacity: 0;
    transform: translate3d(200%, 0px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate3d(100%, 0px, 0px);
  }
`;
