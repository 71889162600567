import { getImage } from 'gatsby-plugin-image';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useOnScreen } from '../../../hooks/useOnScreen';

import { Image as ImageComponent, RedBlock, Relative } from './image.styles';
import { ImageProps } from './image.types';

export const Image = ({
  asset,
  noRadius = false,
  noRed = false,
}: ImageProps) => {
  const [isLoaded, toggleIsLoaded] = useState(false);
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  const imageData = useMemo(() => getImage(asset.gatsbyImageData), [asset]);

  useEffect(() => {
    if (isVisible) toggleIsLoaded(true);
  }, [isVisible]);

  return imageData ? (
    <Relative ref={ref}>
      <>
        <RedBlock
          isVisible={isVisible}
          isLoaded={isLoaded}
          noRadius={noRadius}
          noRed={noRed}
        />
        <ImageComponent image={imageData} alt="" noRadius={noRadius} />
      </>
    </Relative>
  ) : (
    <img src={asset.url} alt="" />
  );
};
