import styled, { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import { ThemeType, getColor, Color } from '../../../theme/color';

export const GlobalStyle = createGlobalStyle<{ theme: ThemeType }>`
	${reset}
	input[type="search"]::-webkit-search-decoration,
	input[type="search"]::-webkit-search-cancel-button,
	input[type="search"]::-webkit-search-results-button,
	input[type="search"]::-webkit-search-results-decoration {
		-webkit-appearance:none;
	}
	input.hide-clear[type=search]::-ms-clear,
	input.hide-clear[type=search]::-ms-reveal {
		display: none;
		width: 0;
		height: 0;
	}
	html, body {
		background-color: ${({ theme }) => theme.background.primary};
		color: ${({ theme }) => theme.foreground.primary};
		box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
		touch-action: pan-y;
	}
	*, *:before, *:after {
		box-sizing: inherit;
		user-drag: none;
	}
	::-moz-selection {
		color: ${getColor(Color.WHITE)};
		background: ${getColor(Color.BLACK)};
	}

	::selection {
		color: ${getColor(Color.WHITE)};
		background: ${getColor(Color.BLACK)};
	}
	input {
		user-select: auto;
		appearance: none;
		border-radius: 0;
		border:none;
    background-image:none;
    background-color:transparent;
    box-shadow: none;
		outline: none;
	}
	button {
		all: unset;
		background: transparent;
		border: none;
	}
	p > a {
		color: ${getColor(Color.BLACK)};
		text-decoration: none;
		border-bottom: 1px solid ${getColor(Color.BLACK)};
		transition: all 0.4s ease !important;

		&:hover {
			color: ${getColor(Color.RED)} !important;
			border-bottom: 1px solid ${getColor(Color.RED)};
		}
	}
	.markdown {
		* {
			all: revert;
		}

		p {
			margin: 0;
		}

		ul {
			margin-top: 0;
		}

		a {
			color: ${getColor(Color.BLACK)};
			text-decoration: none;
			border-bottom: 1px solid ${getColor(Color.BLACK)};
			transition: all 0.4s ease !important;

			&:hover {
				color: ${getColor(Color.RED)} !important;
				border-bottom: 1px solid ${getColor(Color.RED)};
			}
		}
	}
`;

export const Container = styled.div``;
