import styled, { css } from 'styled-components';

import { Font, getFont } from './font';
import { getMediaQuery, ScreenSize, getUnitAsPixels } from './layout';

export enum Type {
  NAVIGATION = 'NAVIGATION',
  HEADING_HERO = 'HEADING_HERO',
  HEADING_1 = 'HEADING_1',
  HEADING_2 = 'HEADING_2',
  HEADING_3 = 'HEADING_3',
  BODY_REGULAR = 'BODY_REGULAR',
  BODY_SMALL = 'BODY_SMALL',
  BODY_REGULAR_BOLD = 'BODY_REGULAR_BOLD',
  BODY_SMALL_BOLD = 'BODY_SMALL_BOLD',
  LABEL = 'LABEL',
  HEADING_HERO_ICON = 'HEADING_HERO_ICON',
  BODY_REGULAR_ICON = 'BODY_REGULAR_ICON',
  DOWNLOAD_ICON = 'DOWNLOAD_ICON',
  BODY_SMALL_ICON = 'BODY_SMALL_ICON',
  QUOTE = 'QUOTE',
  CAPTION = 'CAPTION',
  ACCORDION_ICON = 'ACCORDION_ICON',
}

const typography: { [key in Type]: any } = {
  NAVIGATION: css`
    font-family: ${getFont(Font.FKGroteskLight)};
    font-size: 16px;
    line-height: 1.3;
    text-decoration: none;

    ${getMediaQuery(ScreenSize.LARGE)} {
      font-size: 18px;
    }
  `,

  HEADING_HERO_ICON: css`
    width: ${getUnitAsPixels({ multiplier: 5 })};
    height: auto;

    ${getMediaQuery(ScreenSize.MEDIUM)} {
      width: ${getUnitAsPixels({ multiplier: 9 })};
    }

    ${getMediaQuery(ScreenSize.LARGE)} {
      width: ${getUnitAsPixels({ multiplier: 15 })};
    }
  `,

  HEADING_HERO: css`
    font-family: ${getFont(Font.FKGroteskRegular)};
    font-weight: 400;
    font-size: 45px;
    text-decoration: none;
    line-height: 1.07;
    overflow: hidden;
    word-wrap: break-word;

    ${getMediaQuery(ScreenSize.MEDIUM)} {
      font-size: 90px;
    }

    ${getMediaQuery(ScreenSize.LARGE)} {
      font-size: 144px;
    }
  `,

  HEADING_1: css`
    font-family: ${getFont(Font.FKGroteskRegular)};
    font-weight: 400;
    font-size: 26px;
    text-decoration: none;
    letter-spacing: -0.04em;
    line-height: 1.1;

    ${`${getMediaQuery(ScreenSize.MEDIUM)} and (min-height: 400px)`} {
      font-size: 50px;
    }

    ${`${getMediaQuery(ScreenSize.LARGE)} and (min-height: 700px)`} {
      font-size: 90px;
    }
  `,

  HEADING_2: css`
    font-family: ${getFont(Font.FKGroteskLight)};
    font-weight: 400;
    font-size: 24px;
    text-decoration: none;
    letter-spacing: -0.03em;
    line-height: 1.2;

    ${getMediaQuery(ScreenSize.MEDIUM)} {
      font-size: 40px;
    }

    ${getMediaQuery(ScreenSize.LARGE)} {
      font-size: 70px;
    }
  `,

  HEADING_3: css`
    font-family: ${getFont(Font.FKGroteskLight)};
    font-weight: 400;
    font-size: 22px;
    text-decoration: none;
    letter-spacing: -0.02em;
    line-height: 1.2;

    ${getMediaQuery(ScreenSize.MEDIUM)} {
      font-size: 30px;
    }

    ${getMediaQuery(ScreenSize.LARGE)} {
      font-size: 40px;
    }
  `,

  BODY_REGULAR: css`
    font-family: ${getFont(Font.FKGroteskLight)};
    font-weight: 400;
    font-size: 18px;
    line-height: 1.3;
    letter-spacing: 0;

    ${getMediaQuery(ScreenSize.MEDIUM)} {
      font-size: 22px;
    }

    ${getMediaQuery(ScreenSize.LARGE)} {
      font-size: 25px;
    }
  `,

  BODY_REGULAR_ICON: css`
    width: ${getUnitAsPixels({ multiplier: 2 })};
    height: ${getUnitAsPixels({ multiplier: 2 })};
    ${getMediaQuery(ScreenSize.LARGE)} {
      width: ${getUnitAsPixels({ multiplier: 3 })};
      height: ${getUnitAsPixels({ multiplier: 3 })};
    }
  `,

  DOWNLOAD_ICON: css`
    width: 18px;
    height: 16px;
    ${getMediaQuery(ScreenSize.MEDIUM)} {
      width: 22px;
      height: 20px;
    }
  `,

  BODY_REGULAR_BOLD: css`
    font-family: ${getFont(Font.FKGroteskBold)};
    font-weight: 700;
    font-size: 18px;
    line-height: 1.3;
    letter-spacing: 0;

    ${getMediaQuery(ScreenSize.MEDIUM)} {
      font-size: 22px;
    }

    ${getMediaQuery(ScreenSize.LARGE)} {
      font-size: 25px;
    }
  `,

  BODY_SMALL: css`
    font-family: ${getFont(Font.FKGroteskLight)};
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: 0;

    ${getMediaQuery(ScreenSize.MEDIUM)} {
      font-size: 16px;
    }

    ${getMediaQuery(ScreenSize.LARGE)} {
      font-size: 18px;
    }
  `,

  BODY_SMALL_ICON: css`
    width: ${getUnitAsPixels({ multiplier: 2 })};
    height: ${getUnitAsPixels({ multiplier: 2 })};
  `,

  BODY_SMALL_BOLD: css`
    font-family: ${getFont(Font.FKGroteskBold)};
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: 0;

    ${getMediaQuery(ScreenSize.MEDIUM)} {
      font-size: 16px;
    }

    ${getMediaQuery(ScreenSize.LARGE)} {
      font-size: 18px;
    }
  `,

  LABEL: css`
    font-family: ${getFont(Font.FKGroteskBold)};
    font-weight: 400;
    font-size: 10px;
    text-decoration: none;
    letter-spacing: 0.1em;
    line-height: 1.3em;

    ${getMediaQuery(ScreenSize.LARGE)} {
      font-size: 12px;
    }
  `,

  QUOTE: css`
    font-family: ${getFont(Font.FKGroteskSemiMono)};
    font-weight: 300;
    font-size: 22px;
    line-height: 1.3;
    letter-spacing: 0;

    ${getMediaQuery(ScreenSize.MEDIUM)} {
      font-size: 30px;
    }

    ${getMediaQuery(ScreenSize.LARGE)} {
      font-size: 40px;
    }
  `,

  CAPTION: css`
    font-family: ${getFont(Font.FKGroteskSemiMono)};
    font-weight: 300;
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: 0;

    ${getMediaQuery(ScreenSize.MEDIUM)} {
      font-size: 16px;
    }

    ${getMediaQuery(ScreenSize.LARGE)} {
      font-size: 18px;
    }
  `,

  ACCORDION_ICON: css`
    width: ${getUnitAsPixels({ multiplier: 1.5 })};
    height: ${getUnitAsPixels({ multiplier: 1.5 })};

    ${getMediaQuery(ScreenSize.MEDIUM)} {
      width: ${getUnitAsPixels({ multiplier: 2 })};
      height: ${getUnitAsPixels({ multiplier: 2 })};
    }

    ${getMediaQuery(ScreenSize.LARGE)} {
      width: ${getUnitAsPixels({ multiplier: 2.75 })};
      height: ${getUnitAsPixels({ multiplier: 2.75 })};
    }
  `,
};

export const getTypography = (type: Type): any => typography[type];

export const getTypographyComponent = (type: Type): any =>
  styled.div`
    ${getTypography(type)}
  `;
