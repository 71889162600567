import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React, { ReactNode, FunctionComponent, memo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ThemeProvider } from 'styled-components';

import { PageQuery } from '../../../common/types/graphql';
import { DictionaryProvider } from '../../../context/dictionary';
import { LocaleProvider } from '../../../context/locale';
import '../../../theme/fonts/stylesheet.css';
import { getTheme, Theme, Color } from '../../../theme/color';
import { CookiesModal } from '../../common/cookies-modal';
import { ErrorMessage } from '../../feedback/errorMessage';
import { Footer } from '../footer';
import { Header } from '../header';

import { Container, GlobalStyle } from './page.styles';

interface PageContext {
  locale: string;
  id: string;
  theme: Theme;
}
interface LayoutPageProps {
  children?: ReactNode;
  pageContext: PageContext;
}

const Page: FunctionComponent<LayoutPageProps> = ({
  children,
  pageContext,
}) => {
  const { pathname } = useLocation();
  const data = useStaticQuery<PageQuery>(graphql`
    query Page {
      dictionary: allDatoCmsDictionary {
        nodes {
          locale
          headingNotFound
          textNotFound
          headingError
          newsArticleDownloadPdf
          newsArticleShareThisArticle
          language
        }
      }
      site: allDatoCmsSite {
        nodes {
          locale
          locales
        }
      }
      allDatoCmsPageHome {
        nodes {
          id
          headerTheme
        }
      }
      allDatoCmsPageLegal(filter: { slug: { ne: null } }) {
        nodes {
          __typename
          _allSlugLocales {
            locale
            value
          }
          id
          headerTheme
        }
      }
      allDatoCmsPageModular(filter: { slug: { ne: null } }) {
        nodes {
          __typename
          _allSlugLocales {
            locale
            value
          }
          id
          headerTheme
          body {
            ...ModuleHeading
          }
        }
      }
      allDatoCmsCareersJob(filter: { slug: { ne: null } }) {
        nodes {
          id
          headerTheme
        }
      }
      allDatoCmsNewsArticle(filter: { slug: { ne: null } }) {
        nodes {
          __typename
          _allSlugLocales {
            locale
            value
          }
          id
          headerTheme
        }
      }
      allDatoCmsNewsHome {
        nodes {
          id
          headerTheme
        }
      }
    }
  `);
  const locale = pageContext.locale;
  const dictionary = data.dictionary.nodes.find((n) => n.locale === locale);
  const site = data.site.nodes.find((n) => n.locale === locale);

  if (!site || !dictionary) {
    return null;
  }

  const locales = site.locales as [string];
  const defaultLocale = site.locales[0];
  const allNodes = [
    ...data.allDatoCmsPageHome.nodes,
    ...data.allDatoCmsPageLegal.nodes,
    ...data.allDatoCmsPageModular.nodes,
    ...data.allDatoCmsNewsArticle.nodes,
    ...data.allDatoCmsNewsHome.nodes,
    ...data.allDatoCmsCareersJob.nodes,
  ];
  const _node = allNodes.find((node) => node.id === pageContext.id);
  const dictionaries = data?.dictionary?.nodes;

  const links = locales.map((locale) => {
    let href = pathname.replace(
      new RegExp(`^/(${locales.join('|')})`),
      `/${locale}`,
    );

    if (_node?.__typename === 'DatoCmsNewsArticle') {
      const slug = _node._allSlugLocales.find(
        (slugLocale) => slugLocale.locale === locale,
      )?.value;

      if (slug) {
        href = `/${locale}/news/${slug}`;
      } else {
        href = `/${locale}`;
      }
    }

    if (_node?.__typename === 'DatoCmsPageLegal') {
      const slug = _node._allSlugLocales.find(
        (slugLocale) => slugLocale.locale === locale,
      )?.value;

      if (slug) {
        href = `/${locale}/legal/${slug}`;
      } else {
        href = `/${locale}`;
      }
    }

    if (_node?.__typename === 'DatoCmsPageModular') {
      const slug = _node._allSlugLocales.find(
        (slugLocale) => slugLocale.locale === locale,
      )?.value;

      if (slug) {
        href = `/${locale}/${slug}`;
      } else {
        href = `/${locale}`;
      }
    }

    if (pathname.endsWith('/') && !href.endsWith('/')) {
      href += '/';
    }

    return {
      href,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      label: dictionaries.find((d) => d.locale === locale)!.language,
    };
  });

  const ErrorPage = memo(({ error }: { error: Error }) => (
    <Container>
      <ErrorMessage heading={dictionary?.headingError} text={error.message} />
    </Container>
  ));

  return (
    <ThemeProvider
      theme={
        pageContext.theme ? getTheme(pageContext.theme) : getTheme(Theme.WHITE)
      }
    >
      <LocaleProvider
        locale={locale}
        links={links}
        defaultLocale={defaultLocale}
        locales={locales}
        dictionaries={data?.dictionary?.nodes}
      >
        <DictionaryProvider dictionary={dictionary}>
          <CookiesModal />
          <GlobalStyle />
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <Header
              theme={
                (_node?.headerTheme as Color.WHITE | Color.BLACK | undefined) ??
                Color.BLACK
              }
            />
            <Container>{children}</Container>
            <Footer />
          </ErrorBoundary>
        </DictionaryProvider>
      </LocaleProvider>
    </ThemeProvider>
  );
};

export default Page;
