import { Link as GatsbyLink } from 'gatsby';
import React from 'react';

import { useLinks } from '../../../context/locale';

import { LinkList, Link } from './locales.styles';
import { LocalesProps } from './locales.types';

export const Locales = ({ directionColumn }: LocalesProps) => {
  const links = useLinks();

  return (
    <LinkList directionColumn={directionColumn}>
      {links.map((link) => (
        <Link
          key={link.label}
          as={GatsbyLink}
          to={link.href}
          directionColumn={directionColumn}
        >
          {link.label}
        </Link>
      ))}
    </LinkList>
  );
};
