import { get, map } from 'lodash';

import { getInternalURLFromPage } from './link';
import { TextLink } from './types';
import { DatoCmsLinkExternal, DatoCmsLinkInternal } from './types/graphql';

export const parseLinks = (
  links: (DatoCmsLinkExternal | DatoCmsLinkInternal)[],
): TextLink[] => map(links, (link) => parseLink(link));

export const parseLink = (
  link: DatoCmsLinkExternal | DatoCmsLinkInternal,
): TextLink => {
  const type = get(link, 'model.apiKey');

  if (type === 'link_internal') {
    return parseLinkInternal(link as DatoCmsLinkInternal);
  }

  if (type === 'link_external') {
    return parseLinkExternal(link as DatoCmsLinkExternal);
  }

  return {
    id: link.id,
    isInternal: false,
    label: link.label,
    url: '',
  };
};

export const parseLinkInternal = (link: DatoCmsLinkInternal): TextLink => ({
  id: link.id,
  isInternal: true,
  label: link.label,
  url: getInternalURLFromPage(link.page),
  pageId: link?.page.id,
});

export const parseLinkExternal = (link: DatoCmsLinkExternal): TextLink => ({
  id: link.id,
  isInternal: false,
  label: link.label,
  url: link.url,
});

export const getTypeName = (
  block: any,
  fallback?: string,
): string | undefined => {
  return get(block, 'model.apiKey', fallback);
};

export const getTheme = (block: any): string => {
  return get(block, 'theme');
};
