import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';
import React, { FunctionComponent } from 'react';
import { ThemeProvider } from 'styled-components';

import { parseLinks } from '../../../common/content';
import { plainTextToHTML } from '../../../common/string';
import { DatoCmsFooterConnection } from '../../../common/types/graphql';
import { useLocale } from '../../../context/locale';
import { getTheme, Theme } from '../../../theme/color';
import { Container } from '../../common/container';
import { Image } from '../../common/image';
import { Locales } from '../../common/locales';

import {
  FooterWrapper,
  Socials,
  LocalesWrapper,
  InfoRow,
  Logo,
  LegalInfoWrapper,
  LinkList,
  LinkListItem,
  LegalInfoLinkList,
  LegalInfoLinkListItem,
  LegalLink,
  LegalInfo,
  Link,
} from './footer.styles';

export const Footer: FunctionComponent = () => {
  const locale = useLocale();

  const data: { footer: DatoCmsFooterConnection } = useStaticQuery(graphql`
    query Footer {
      footer: allDatoCmsFooter {
        nodes {
          locale
          linksPrimary {
            ...LinkInternal
            ...LinkExternal
          }
          linksSecondary {
            ...LinkInternal
            ...LinkExternal
          }
          linksLegal {
            ...LinkInternal
            ...LinkExternal
          }
          legal
          logo {
            gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
            url
          }
        }
      }
    }
  `);

  const footer = data.footer.nodes.find((n) => n.locale === locale);

  if (!footer) {
    return null;
  }

  const primary = parseLinks(footer.linksPrimary);
  const secondary = parseLinks(footer.linksSecondary);
  const legal = parseLinks(footer.linksLegal);
  const logo = footer?.logo;

  return (
    <FooterWrapper>
      <ThemeProvider theme={getTheme(Theme.GREY_DARK)}>
        <Container>
          {/* ----- Top Row ----- */}
          <LinkList>
            {primary.map((link) => (
              <LinkListItem key={link.id}>
                {link.isInternal ? (
                  <Link as={GatsbyLink} to={link.url}>
                    {link.label}
                  </Link>
                ) : (
                  <Link href={link.url}>{link.label}&#8599;</Link>
                )}
              </LinkListItem>
            ))}
          </LinkList>

          <Socials>
            <LinkList>
              {secondary.map((link) => (
                <LinkListItem key={link.id}>
                  {link.isInternal ? (
                    <Link as={GatsbyLink} to={link.url}>
                      {link.label}
                    </Link>
                  ) : (
                    <Link href={link.url}>{link.label}&#8599;</Link>
                  )}
                </LinkListItem>
              ))}
            </LinkList>
          </Socials>
          {process.env.GATSBY_LOCALIZATION_ENABLE && (
            <LocalesWrapper>
              <Locales directionColumn />
            </LocalesWrapper>
          )}

          {/* ----- Bottom Row ------ */}
          <InfoRow>
            <Logo>
              <Image asset={logo} />
            </Logo>

            <LegalInfoWrapper>
              <LegalInfoLinkList>
                {legal.map((link) => (
                  <LegalInfoLinkListItem key={link.id}>
                    {link.isInternal ? (
                      <LegalLink as={GatsbyLink} to={link.url}>
                        {link.label}
                      </LegalLink>
                    ) : (
                      <LegalLink href={link.url}>{link.label}</LegalLink>
                    )}
                  </LegalInfoLinkListItem>
                ))}
              </LegalInfoLinkList>
              <LegalInfo>{plainTextToHTML(footer.legal)}</LegalInfo>
            </LegalInfoWrapper>
          </InfoRow>
        </Container>
      </ThemeProvider>
    </FooterWrapper>
  );
};
