import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { Color, getColor } from '../../../theme/color';
import {
  getVerticalSpacingAsStyle,
  VerticalSpacingType,
  VerticalSpacingVariant,
  borderRadius,
} from '../../../theme/layout';

export const Image = styled(GatsbyImage)<{ noRadius: boolean }>`
  ${getVerticalSpacingAsStyle(VerticalSpacingType.MARGIN, {
    bottom: VerticalSpacingVariant.SMALL,
  })}
  height: 100%;
  z-index: 1;
  border-radius: ${({ noRadius }) => (noRadius ? `none` : borderRadius)};

  &:last-child {
    margin: 0;
  }
`;

export const Relative = styled.div`
  position: relative;
  height: 100%;
`;

export const RedBlock = styled.div<{
  isVisible: boolean;
  isLoaded: boolean;
  noRadius: boolean;
  noRed: boolean;
}>`
  display: ${({ noRed }) => (noRed ? 'none' : 'block')};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${getColor(Color.RED)};
  z-index: 2;
  transition: opacity linear 0.25s;
  opacity: ${({ isVisible, isLoaded }) => (isVisible ? 0 : isLoaded ? 0 : 1)};
  border-radius: ${({ noRadius }) => (noRadius ? `none` : borderRadius)};

  &:last-child {
    margin: 0;
  }
`;
