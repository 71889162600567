import { ReactNode } from 'react';

import { VerticalSpacingVariant } from '../../../theme/layout';

export interface ContainerProps {
  children: ReactNode;
  spaceTop?: VerticalSpacingVariant;
  spaceBottom?: VerticalSpacingVariant;
  borderTop?: boolean;
  borderBotton?: boolean;
  display?: ContainerDisplay;
  innerPageTitle?: boolean;
}

export enum ContainerDisplay {
  GRID = 'grid',
  BLOCK = 'block',
}
